import { useQuery, UseQueryOptions } from 'react-query';

import { RequestContext } from '@common/api-request';

import { getCartItemsCount } from '../graphql/getCartItemsCount.2';

export const cartItemsCountQueryOption = (
  context?: RequestContext,
): UseQueryOptions<number, unknown, number, ['getCartItemsCount']> => ({
  queryFn: async () => {
    const result = await getCartItemsCount(undefined, context ? { context } : undefined);

    return result.data.cart_items_count;
  },
  queryKey: ['getCartItemsCount'],
  refetchOnWindowFocus: true,
  refetchOnMount: true,
  staleTime: 0,
});

export function useCartCountQuery() {
  return useQuery(cartItemsCountQueryOption());
}
